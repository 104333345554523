// @import 'https://cdn.jsdelivr.net/npm/bootstrap@5.3.0/dist/css/bootstrap.min.css';
@import '~bootstrap/scss/functions';
@import './variables';
@import './mixins';
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/variables-dark';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/utilities';

main {
  margin-top: 88px;
  @media (max-width: 1440px) {
    margin-top: 130px;
  }
  @media (max-width: 575px) {
    margin-top: 123px;
  }
}

body.en {
  .goTop {
    p {
      writing-mode: vertical-lr;
    }
  }
}

.goTop {
  border-radius: 8px 0px 0px 8px;
  background: linear-gradient(180deg, #014b99 0%, #0267b9 100%);
  box-shadow: 0px 0px 4px 0px rgba(0, 0, 0, 0.25);
  display: flex;
  width: 40px;
  padding: 16px 10px 20px 10px;
  flex-direction: column;
  align-items: center;
  gap: 4px;
  position: fixed;
  bottom: 160px;
  right: 0;
  z-index: 900;
  cursor: pointer;
  transition: 0.3s linear;
  @media (max-width: 575px) {
    bottom: 140px;
  }
  &:hover {
    transition: 0.3s linear;
    background: #1a5ea5;
  }
  &:active {
    transition: 0.3s linear;
    background: #4d83ba;
  }
  &.active {
    transition: 0.3s linea;
    background: #b3cae1;
    &:hover {
      transition: 0.3s linear;
      background: #1a5ea5;
    }
  }
  p {
    color: #fff;
    font-size: 16px;
    font-weight: 700;
    padding-left: 2px;
  }
}

.shadow {
  box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05) !important;
  @media (max-width: 575px) {
    box-shadow: 0px 0px 30px 0px rgba(0, 0, 0, 0.05) !important;
  }
}

.radius-custom {
  border-radius: 8px;
}

ol,
ul {
  margin-bottom: 0;
}
