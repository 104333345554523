$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1920px,
);

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1140px,
  xxl: 1152px,
);

$font-family-sans-serif: 'PingFang TC', 'Noto Sans TC', 'SF Pro Text', 'Roboto', Helvetica, Arial;

/* Colors */
$gray-900: #333;
$gray-100: #f5f5f5;
$blue: #1c4e79;
$blue-300: #2e89ae;
$border-color: #999999;
$white: #ffffff;
$tomato: #f96934;

$component-active-color: $blue;
$form-check-input-bg: $blue;
$form-check-input-checked-color: $blue;
$form-check-input-checked-bg-color: $blue;
$form-check-input-checked-border-color: $blue;
$form-check-input-indeterminate-color: $blue;
