@import "@styles/vendors/_custom.scss";

.sitemap {
  background-color: #eaeaea;
  .custom-container {
    width: 1920px;
    max-width: 100%;
    margin: 0 auto;
    padding: 40px 120px;
    @media (max-width: 1440px) {
      padding: 40px 24px;
    }
  }
  .title,
  .title > a {
    color: #333;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px; /* 120% */
    margin-bottom: 24px;
  }

  a {
    color: #333;
    font-size: 14px;
    font-weight: 400;
    line-height: 16px;
  }
  .content {
    .col {
      width: calc(100% / 10);
      margin-bottom: 10px;
      @media (max-width: 1280px) {
        width: calc(100% / 6);
      }
      @media (max-width: 991px) {
        width: calc(100% / 4);
      }
      @media (max-width: 575px) {
        width: 100%;
      }
      &.line {
        border: 1px solid #999;
        border-width: 0 0 0 1px;
        padding: 20px 10px 0 10px;
        @media (max-width: 575px) {
          border-top: 1px solid #999;
          border-left: none;
          padding: 20px 10px 0 10px;
        }
      }
      .collapse:not(.show) {
        display: block;
        @media (max-width: 991px) {
          display: none;
        }
      }
      .content-title {
        margin-bottom: 16px;
        color: #333;
        font-size: 16px;
        font-weight: 600;
        line-height: 24px;
      }
      a.content-text {
        display: flex;
        margin-bottom: 10px;
        padding-bottom: 2px;
        border-bottom: solid 1px #eaeaea;
        transition: 0.3s linear;
        width: fit-content;
        &:hover {
          text-decoration: none;
          border-bottom: solid 1px #333;
        }
        .icon {
          position: relative;
          top: -1px;
          margin-left: 2px;
          display: inline-block;
          vertical-align: text-top;
          img {
            width: 20px;
            height: 20px;
          }
          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
      .content-block {
        line-height: 16px;
        margin-bottom: 32px;
        a {
          margin-bottom: 10px;
          padding-bottom: 2px;
          display: block;
        }
      }
      .hidden-lg {
        margin-top: 10px;
        @media (max-width: 575px) {
          margin-bottom: 10px !important;
        }
      }
      .more-action {
        &:not(.collapsed) {
          .expand_more {
            display: none;
          }

          .expand_less {
            display: block;
          }
        }

        .expand_more {
          display: block;
        }

        .expand_less {
          display: none;
        }

        .expand_more,
        .expand_less {
          .icon {
            svg {
              width: 20px;
              height: 20px;
            }
          }
        }
      }
    }
  }
}
