// textbox: 限制文字最大行數
@mixin textbox($line) {
  display: block;
  display: -webkit-box;
  display: -moz-box;
  display: -ms-box;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: $line;
  height: auto;
}
.txt-l1 {
  @include textbox(1);
}
.txt-l2 {
  @include textbox(2);
}
.txt-l3 {
  @include textbox(3);
}

.txt-l4 {
  @include textbox(4);
}

.txt-l5 {
  @include textbox(5);
}

.txt-l6 {
  @include textbox(6);
}

/* 單行省略 */
.text-ellipsis {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
